<template>
    <div class="job-detail">
        <div class="content">
            <p class="title">{{ title }}</p>
            <p class="gery">发布时间 : <span style="margin-left: 4rem">{{ getDate(createTime) }}</span></p>
            <p class="grey">
                <span>{{ postBase }}</span>
                <span>{{ workingCompany }}</span>
                <span>{{ salary }}</span>
            </p>
            <van-divider content-position="left">职位描述</van-divider>
            <p v-if="postDescribe" v-html="postDescribe" class="desc"></p>
            <div style="text-align:center">
                <van-button class="btn" @click="goDetail()">投递简历</van-button>
            </div>
        </div>
        <div class="footer"></div>
    </div>
</template>
<script>
import { getJobDetail } from '@/api/index.js';
export default {
    data() {
        return {
            title: '',
            createTime: '',
            postBase: '',
            postDescribe: '',
            salary: '',
            workingCompany: ''
        }
    },
    methods: {
        getData() {
            const id = this.$route.params.id || '';
            if (!id) return;
            getJobDetail(id).then(res => {
                this.title = res.postName || '';
                this.createTime = res.createTime || '';
                this.postBase = res.postBase || '';
                this.workingCompany = res.workingCompany || '';
                this.postDescribe = res.postDescribe || '';
                this.salary = res.salary || '';
            });
        },
        goDetail() {
            const id = this.$route.params.id || '';
            if (!id) return;
            this.$router.push({ 
                path: `/join/send/${id}`, 
            });
        },
        replaceSpecialChar(detail) {
            return detail.replace(/<img[^>]*>/gi, function (match) {
                return match.replace(/(style="(.*?)")|(width="(.*?)")|(height="(.*?)")/ig, 'width=350rpx');
            });
        },
        getDate(timeStr) {
            return timeStr && timeStr.length > 10 ? timeStr.substring(0, 10) : '-';
        }
    },
    mounted() {
        this.getData();
    }
}
</script>
<style lang="scss" scoped>
.job-detail {
    min-height: calc(100vh - 91px);
    box-sizing: border-box;
    .content {
        padding: 4%;
    }

    .title {
        font-size: 32rem;
        margin-bottom: 36rem;
        font-weight: bold;
    }

    .grey {
        color: #999;
        font-size: 26rem;
        margin-bottom: 30rem;

        &>span {
            display: inline-block;
            margin-right: 2%;
            line-height: 1.8em;
        }
    }

    :deep(.van-divider) {
        color: #333;
    }

    .desc {
        font-size: 26rem;
        width: 100%;
        overflow: auto;
        line-height: 2em;
    }

    .btn {
        margin: 24rem auto;
        color: #fff;
        background-color: #005554;
        height: 66rem;
        line-height: 66rem;
        border-radius: 8rem;
    }

    .footer {
        position: relative;
        background: url('../assets/img/footer.jpg') no-repeat;
        background-size: 100% 100%;
        height: 0;
        padding-bottom: 41%;
        color: #fff;
    }
}
</style>
<style lang="scss">
.job-detail {
    .van-divider--content-left:before {
        margin-right: 0;
        max-width: 0;
    }

    .van-divider:after {
        margin-left: 2%;
        border-color: #999;
    }
}
</style>